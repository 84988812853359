import { motion, useAnimation } from 'framer-motion'
import { Link } from 'gatsby'
import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import styled, { css } from 'styled-components'
import theme from '../theme/theme'
import isAnimationEnabled from '../utils/isAnimationEnabled'
import { aboutRowClassName } from './AboutColumns.nostyle'
import AspectRatio from './AspectRatio'

const Title = styled.h2`
  font-style: normal;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 19px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 20px;
    line-height: 24px;
  }
`

const Paragraph = styled.p`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #ffffff;
  margin-bottom: 14px;
  font-size: 12px;
  line-height: 14px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 12px;
    line-height: 14px;
  }
`

const button = css`
  text-decoration: none;
  padding: 10px 15px;
  align-self: center;
  max-width: 206px;
  width: 100%;
  border: none;
  border-radius: 39px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  background-color: ${({ theme }) => theme.colors.primary};
  transition: background-color 300ms ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.tertiary};
  }
`

const Button = styled.button`
  ${button}
`

const ButtonLink = styled(Link)`
  ${button}
`

const ExternalLink = styled.a`
  ${button}
`

interface RowWrapperProps {
  color: 'primary' | 'secondary' | 'tertiary'
}

const RowWrapper = styled.div<RowWrapperProps>`
  background-color: ${({ theme, color }) => theme.colors[color]};
  display: flex;
  overflow-x: hidden;

  // mobile
  & {
    padding: 7px 0;
    box-shadow: inset 0 7px 0 0 #F8F9FC, inset 0 -7px 0 0 #F8F9FC;
  }

  &:first-child {
    padding: 14px 0 7px 0;
    box-shadow: inset 0 14px 0 0 #F8F9FC, inset 0 -7px 0 0 #F8F9FC;
  }

  &:last-child {
    padding: 7px 0 14px 0;
    box-shadow: inset 0 7px 0 0 #F8F9FC, inset 0 -14px 0 0 #F8F9FC;
  }

  &:not(:last-child) {
    margin-bottom: -7px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    &,
    &:first-child,
    &:last-child {
      padding: 14px 0;
      box-shadow: inset 0 14px 0 0 #F8F9FC, inset 0 -14px 0 0 #F8F9FC;
    }

    &:not(:last-child) {
      margin-bottom: -14px;
    }
  }
`

const RowLeft = styled(motion.div)`
  flex: 1;
  min-height: 200px;
  display: flex;
  justify-content: flex-end;
`

const RowRight = styled(motion.div)`
  flex: 1;
  min-height: 200px;
`

const AspectRatioWrapper = styled(AspectRatio)`
  width: 100%;
  max-width: 273px;
  height: 100%;
`

const Img = styled.img`
  box-shadow: 0 0 11px rgba(0, 0, 0, 0.75);
  object-fit: cover;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 11px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 16px;
  }
`

interface RowProps {
  color: 'primary' | 'secondary' | 'tertiary'
  image: string
  inverse?: boolean
  alt: string
  children: React.ReactNode
}

const Row: React.FC<RowProps> = ({ inverse, image, color, children, alt }) => {
  const controls = useAnimation()
  const [ref, inView] = useInView()

  const enabled = isAnimationEnabled()

  useEffect(() => {
    if (!enabled) {
      return
    }

    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])

  const imageEl = <Img src={image} alt={alt} />

  const contentEl = <Content>{children}</Content>

  const left = inverse ? contentEl : imageEl

  const right = inverse ? imageEl : contentEl

  return (
    <RowWrapper ref={ref} color={color}>
      <RowLeft
        className={aboutRowClassName}
        animate={controls}
        initial={enabled ? 'hidden' : 'visible'}
        transition={{
          duration: theme.transitions.fadeUp.duration,
          type: 'linear',
        }}
        variants={{
          visible: { x: 0 },
          hidden: { x: '-120%' },
        }}
      >
        <AspectRatioWrapper
          mobile={{ width: 179, height: 188 }}
          desktop={{ width: 273, height: 262 }}
        >
          {left}
        </AspectRatioWrapper>
      </RowLeft>
      <RowRight
        className={aboutRowClassName}
        animate={controls}
        initial={enabled ? 'hidden' : 'visible'}
        transition={{
          duration: theme.transitions.fadeUp.duration,
          type: 'linear',
        }}
        variants={{
          visible: { x: 0 },
          hidden: { x: '120%' },
        }}
      >
        <AspectRatioWrapper
          mobile={{ width: 179, height: 188 }}
          desktop={{ width: 273, height: 262 }}
        >
          {right}
        </AspectRatioWrapper>
      </RowRight>
    </RowWrapper>
  )
}

const AboutColumns = styled.div`
  
`

export default Object.assign(AboutColumns, {
  Row: Object.assign(Row, {
    Title,
    Paragraph,
    Button: Object.assign(Button, {
      Link: ButtonLink,
      ExternalLink,
    }),
  }),
})
