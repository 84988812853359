import React from 'react'
import { PageProps } from 'gatsby'
import ServicesGrid from '../../components/ServicesGrid'
import services from '../../data/services'
import AboutContent from '../../components/AboutContent'
import AboutBanner from '../../components/AboutBanner'
import ServicesGridHeader from '../../components/ServicesGridHeader'
import ServicesGridDescription from '../../components/ServicesGridDescription'
import AboutColumns from '../../components/AboutColumns'
import { LayoutProps } from '../../components/Layout.context'
import { magasinLink } from '../../data/menu'
import { Helmet } from 'react-helmet'
import BannerVideoBackground from '../../components/BannerVideoBackground'

const slogan = `Nous, c’est Save, et nous sommes à votre service`

const AproposPage: React.FC<PageProps> = () => {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          title="Leader Européen de la réparation"
          content="Découvrez Save, votre partenaire de confiance pour la réparation d'appareils, la protection et récupération de données, ainsi que la vente d'appareils reconditionnés.
          Apprenez-en plus sur notre engagement à fournir des solutions fiables et innovantes pour répondre à vos besoins technologiques."
        />
      </Helmet>

      <AboutBanner
        title="Save"
        description="Leader Européen de la réparation"
      />

      <AboutContent>
        <AboutContent.Title>Qui sommes-nous ?</AboutContent.Title>
        <AboutContent.Paragraph>
          Nous sommes Save et nous croyons fermement que tout produit
          électronique a plusieurs vies. C’est pourquoi nous œuvrons à
          développer la seconde vie de vos téléphones et produits électroniques.
        </AboutContent.Paragraph>
        <AboutContent.Paragraph>
          Nos services, de la réparation de smartphones à la vente de produits
          reconditionnés, ont pour vocation de développer l’économie circulaire,
          afin que votre téléphone ne soit plus qu’un simple produit de
          consommation.
        </AboutContent.Paragraph>
        <BannerVideoBackground
          width={590}
          height={321}
          ratio={852 / 480}
          cropFactor={1}
          src="/videos/save_wagram.mp4"
          type="video/mp4"
          poster="/videos/save_wagram.jpg"
        />
      </AboutContent>

      <AboutColumns>
        <AboutColumns.Row
          color="secondary"
          image="/images/store/reseau-save-store.jpg"
          alt="Réseau Save Store"
        >
          <AboutColumns.Row.Title>Un réseau</AboutColumns.Row.Title>
          <AboutColumns.Row.Paragraph>
            Nous avons près de 150 points de vente en France, le plus important
            réseau de réparation dans l'hexagone.
          </AboutColumns.Row.Paragraph>
          <AboutColumns.Row.Paragraph>
            Chacun de nos magasins mène une croisade pour la plus grande
            satisfaction de nos clients et contre l’obsolescence programmée !
          </AboutColumns.Row.Paragraph>
          <AboutColumns.Row.Button.ExternalLink href={magasinLink}>
            Nous trouver
          </AboutColumns.Row.Button.ExternalLink>
        </AboutColumns.Row>
        <AboutColumns.Row
          inverse
          color="primary"
          image="/images/store/expertise-save-store.jpg"
          alt="Expertise Save Store"
        >
          <AboutColumns.Row.Title>Notre expertise</AboutColumns.Row.Title>
          <AboutColumns.Row.Paragraph>
            Nous existons afin de vous permettre de mieux utiliser vos produits
            électroniques. Et parce que nous le faisons bien, Samsung, Huawei,
            Honor et d’autres nous font confiance en nous gratifiant d’un
            agrément.
          </AboutColumns.Row.Paragraph>
          <AboutColumns.Row.Paragraph>
            Venir chez Save, c’est l’assurance d’un service conforme à ce que
            veulent les fabricants de vos produits !
          </AboutColumns.Row.Paragraph>
        </AboutColumns.Row>
        <AboutColumns.Row
          color="secondary"
          image="/images/store/histoire-save-store.jpg"
          alt="Histoire Save Store"
        >
          <AboutColumns.Row.Title>Notre histoire</AboutColumns.Row.Title>
          <AboutColumns.Row.Paragraph>
            Créée en 2006, Point Service Mobiles a racheté la société Save en
            2019 et choisit en 2020 d’utiliser le nom de ce dernier, plus
            évocateur de sa mission profonde et de son engagement.
          </AboutColumns.Row.Paragraph>
        </AboutColumns.Row>
      </AboutColumns>

      <ServicesGridHeader>{slogan}</ServicesGridHeader>
      <ServicesGrid items={services} />
      <ServicesGridDescription>{slogan}</ServicesGridDescription>
    </>
  )
}

const layoutProps: LayoutProps = {
  title: `Découvrez l'histoire de Save créée en 2006 et leader national de la réparation`,
  breadcrumb: [{ label: 'À propos' }]
}

export default Object.assign(AproposPage, {
  layoutProps,
})
